import React from 'react';
import {css} from '@emotion/core';
import {graphql, useStaticQuery} from 'gatsby';
import Img from "gatsby-image";
import styled from '@emotion/styled';
import Layout from '../components/layout';
import WebApplications from '../assets/webApplications.svg';
import MobileApps from '../assets/mobileApps.svg';
import SystemsIntegration from '../assets/systemsIntegration.svg';
import MultiLingualApps from '../assets/multiLingualApps.svg';
import LogoBHP from '../assets/logoBHP.svg';
import LogoCRES from '../assets/logoCRES.svg';
import LogoSAWU from '../assets/logoSAWU.svg';
import LogoSOUTH32 from '../assets/logoSOUTH32.svg';
import LogoAdapt from '../assets/logoAdapt.svg';

import ActionLink from '../components/action-link';

const HomeSection = styled('section')`
  padding: 2rem .5rem;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
`,
SvgImage = styled('div')`
  width: 100%;
  margin-top: -100px;
  max-height: 196px;
  max-width: 100%;

  img { 
    max-height: 196px;
  }
`,
Service = styled('div')`
  background: #F3F3F3;
  text-align: center;
  border-radius: 10px;
  width: 49%;
  padding: 15px;
  margin: 100px 0 1rem;

  h3 {
    padding-top: 10px;
  }

  @media only screen and (max-width : 600px) {
    width: 96%;
  }
`;

export default () => {
  const {sawuDashboard} = useStaticQuery(graphql`
    query {
      sawuDashboard: file(relativePath: { eq: "sawu/desktopDashboard.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Layout page="Home">
        <HomeSection className="services" css={css`
          padding-top: 0 !important;
          
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
            /* IE10+ specific styles go here */  
            padding-top: 10px !important;
          }

          h2 { font-size: 30pt; }
        `}>
          <h2>Local specialists in custom application development and systems integration</h2>
          <div css={css`display: block; margin-left: auto; margin-right: auto; max-width: 700px;`}>
            <p>We understand businesses and can help you automate and streamline your current processes or turn your
            ideas into a reality</p>
          </div>
          <div css={css`
            padding: 1rem .25rem 1rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            @media only screen and (max-width : 600px) {
              flex-direction: column;
            }
          `}>
            <Service>
              <SvgImage>
                <img src={WebApplications} alt="Web Application Development" />
              </SvgImage>
              <h3>Web Application Development</h3>
              <p>Do you have a paper-based process or a clunky out-dated system that really needs to
                be online? We can help you develop something from scratch or work with your existing
                systems to bring them into the 21st century.</p>
            </Service>

            <Service>
              <SvgImage>
                <img src={MobileApps} alt="Mobile Application Development" />
              </SvgImage>
              <h3>Mobile App Development</h3>
              <p>Do you have staff in the field that need to access your internal systems? Do
                you have a paper-based timesheet or job card that has to be manually entered in
                another system? We can help you build an app for all major device platforms.</p>
            </Service>

            <Service>
              <SvgImage>
                <img src={SystemsIntegration} alt="Systems Integration" />
              </SvgImage>
              <h3>Systems Integration</h3>
              <p>Do you have multiple systems that don't integrate? Are you manually entering
                data from one system to another? We can help integrate your systems to fully automate
                any manual process to save you time and remove human error.</p>
            </Service>

            <Service>
              <SvgImage>
                <img src={MultiLingualApps} alt="Multilingual Applications" />
              </SvgImage>
              <h3>Multilingual Applications</h3>
              <p>Do you have a global business and need a multilingual solution? Do you have an existing
                 English only system that needs to be multilingual? We’ve worked on several projects
                 to implement multilingual support and can help you do the same.</p>
            </Service>
          </div>

          <ActionLink to="/services">Our Services</ActionLink>
        </HomeSection>

        <div css={css`
          background: #0E75C1;

          svg {
            width: 100%;
            left: 0;
            bottom: -1px;
            height: 40px;
            fill: #0E75C1;
            background: #fff;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
              /* IE10+ specific styles go here */  
              overflow: hidden;
              bottom: 0;
              height: 39px;
            }
    
            path:nth-of-type(1) {
              opacity: 0.15;
            }
            path:nth-of-type(2) {
              opacity: 0.3;
            }

            &.bottom {
              fill: #fff;
              background: #0E75C1;
              margin-bottom: -8px;
              height: 30px;
            }
          }
        `}>
          <svg xmlns="http://www.w3.org/2000/svg" className="top" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z"></path>
            <path d="M 0 45 s 271 45.13 500 32 c 157 -9 330 -47 515 -63 v 86 h -1015 z"></path>
            <path d="M 0 58 s 188.29 32 508 32 c 290 0 494 -35 494 -35 v 45 h -1002 z"></path>
          </svg>
          <HomeSection className="work" css={css`
            color: #fff;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 600px) {
              flex-direction: column;
            }

            h2, h3, p { color: #fff; margin-bottom: 1.5rem; }
            div { 
              width: 48%; 

              @media screen and (max-width: 600px) {
                order: 2;
                width: 100%;
                padding: 0 1rem;
              }
            }
            .gatsby-image-wrapper {
              max-width: 48%;
              border: 10px solid #fff;
              border-radius: 20px;
              box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.35);

              @media screen and (max-width: 600px) {
                order: 1;
                max-width: 96%;
                margin-bottom: 1rem;
              }
            }
          `}>
            <div>
              <h2>Our Latest Work</h2>
              <h3>Expanding the BHP Local Buying Program to South America</h3>
              <p>We worked with BHP, C-Res and SAWU to develop a multilingual platform enabling local
              businesses to supply goods and services to BHP operations in Chile.</p>
              <ActionLink to="/work">Our Work</ActionLink>
            </div>
            <Img fluid={sawuDashboard.sharp.fluid} fadeIn />
          </HomeSection>
          <svg xmlns="http://www.w3.org/2000/svg" className="bottom" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z"></path>
            <path d="M 0 45 s 271 45.13 500 32 c 157 -9 330 -47 515 -63 v 86 h -1015 z"></path>
            <path d="M 0 58 s 188.29 32 508 32 c 290 0 494 -35 494 -35 v 45 h -1002 z"></path>
          </svg>
        </div>

        <HomeSection className="clients" css={css`
          padding-bottom: 0;

          div {
            padding-top: 1rem;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            align-items: center;
          }

          @media screen and (min-width: 1000px) {
            background-size: 105%;
          }

          img { 
            max-height: 50px;
            margin: 0 30px 30px;

            @media screen and (max-width: 400px) {
              max-height: 40px;
              margin: 0 10px 20px;
            }
          }
        `}>
          <h2>Our team has completed projects for:</h2>
          <div css={css`
            img {
              filter: grayscale(1);
            }
          `}>
            <img src={LogoBHP} alt="BHP" />
            <img src={LogoCRES} alt="C-Res" />
            <img src={LogoSAWU} alt="SAWU" />
            <img src={LogoSOUTH32} alt="South32" />
            <img src={LogoAdapt} alt="Adapt Essential Services" />
          </div>
        </HomeSection>
      </Layout>
    </>
  );
};
